/**
 * @Description 套餐配置
 * @Author zhikangfan
 */


/**
 * VIP 等级定义
 */
export const VIP_LEVEL = {
  NON_VIP: 0, // 非vip
  COUNT_VIP: 5, // 次数vip
  TIME_VIP: 10, // 时间vip
  PERMANENT_VIP: 100, // 永久会员
  THREE_DAY_VIP: 7, // 3天会员
  YEAR_VIP: 10, // 年会员
}

/**
 * 普通套餐
 */
export const packageList  = [
  {
    id: 4,
    title: '按张付费·10张',
    description: '下载张数永久有效',
    unitPrice: 1.99, // 单价
    originPrice: '', // 原价
    price: 19.9, // 售价
    discountPrice: '已优惠88元',
    unit: '/张',
    corner: '',
    defaultChecked: false,
    level: [VIP_LEVEL.NON_VIP, VIP_LEVEL.YEAR_VIP]
  },
  {
    id: 3,
    title: '按张付费·1张',
    description: '下载张数永久有效',
    unitPrice: 9.9, // 单价
    originPrice: '',
    price: 9.9,
    discountPrice: '',
    unit: '/张',
    corner: '',
    defaultChecked: false,
    level: [VIP_LEVEL.NON_VIP, VIP_LEVEL.YEAR_VIP]
  },
  {
    id: 2,
    title: '年会员',
    description: '低至0.13元/天',
    unitPrice: 49, // 单价
    originPrice: '179',
    price: 49,
    discountPrice: '立减130元',
    unit: '',
    corner: '',
    defaultChecked: false,
    level: [VIP_LEVEL.NON_VIP, VIP_LEVEL.YEAR_VIP]
  },
  {
    id: 1,
    title: '终身会员',
    description: '终身免费使用',
    unitPrice: 69, // 单价
    originPrice: '569',
    price: 69,
    discountPrice: '直降500元',
    unit: '',
    corner: '限时促销',
    defaultChecked: true,
    level: [VIP_LEVEL.NON_VIP]
  },
  {
    id: 5,
    title: '终身会员',
    description: '终身免费使用',
    unitPrice: 39, // 单价
    originPrice: '569',
    price: 39,
    discountPrice: '直降530元',
    unit: '',
    corner: '限时升级',
    defaultChecked: true,
    level: [VIP_LEVEL.COUNT_VIP, VIP_LEVEL.THREE_DAY_VIP, VIP_LEVEL.YEAR_VIP]
  },
  {
    id: 8,
    title: '按张付费·10张',
    description: '下载张数永久有效',
    unitPrice: 1.99, // 单价
    originPrice: '',
    price: 19.9,
    discountPrice: '已优惠88元',
    unit: '/张',
    corner: '',
    defaultChecked: false,
    level: [VIP_LEVEL.COUNT_VIP]
  }
]

/**
 * 挽留套餐
 */
export const retentionList = [
  {
    id: 5,
    title: '终身会员',
    description: '永久使用，限时促销',
    unitPrice: 39, // 单价
    originPrice: '569', // 原价
    price: 39, // 售价
    discountPrice: '',
    unit: '',
    corner: '一年仅一次',
    defaultChecked: true,
    level: [VIP_LEVEL.NON_VIP, VIP_LEVEL.COUNT_VIP]
  },
  {
    id: 11,
    title: '3天会员',
    description: '不限次数',
    unitPrice: 19.9, // 单价
    originPrice: '', // 原价
    price: 19.9, // 售价
    discountPrice: '',
    unit: '',
    corner: '',
    defaultChecked: false,
    level: [VIP_LEVEL.NON_VIP, VIP_LEVEL.COUNT_VIP]
  },
]

export const sougou_packageList = []

export const sougou_retentionList = []
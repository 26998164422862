<template>
  <div class="navList">
    <div v-for="(menu, idx) in menus" :key="idx" class="navItem">
      <div :class="{ navItemContent: true, active: routeName === menu.key }">
        <router-link class="menuTitle" :to="menu.children[0].link">
          <span class="txt">{{ menu.title }}</span>
          <span class="icon"></span>
        </router-link>
      </div>
      <div class="menusList">
        <router-link
          class="menuItem"
          :to="item.link"
          v-for="(item, idx) in menu.children"
          :key="item.title + '_' + idx"
        >
          <img :src="item.icon" alt="" class="menuIcon" />
          <span>{{ item.title }}</span>
        </router-link>
      </div>
    </div>
<!--    <div class="navItem">-->
<!--      <div :class="{ navItemContent: true }">-->
<!--        <a class="menuTitle" href="https://www.miaokou.cn/?from=imggeshicn" target="_blank">-->
<!--          <span class="txt">在线抠图</span>-->
<!--        </a>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>
<script>
export default {
  name: 'NavMenu',
  components: {},
  props: {
    menus: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      routeName: ''
    }
  },
  watch: {
    $route: {
      handler(val) {
        let name = 'compress'
        if (val.matched.some(item => item.path === '/edit')) {
          name = 'edit'
        } else if (val.matched.some(item => item.path === '/convert')) {
          name = 'convert'
        } else if (val.matched.some(item => item.path === '/pdf')) {
          name = 'pdf'
        } else if (val.matched.some(item => item.path === '/gif')) {
          name = 'gif'
        }
        this.routeName = name
      },
      immediate: true,
      deep: true
    }
  }
}
</script>
<style scoped lang="less">
@import url('./index.less');
</style>

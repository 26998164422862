<template>
  <div class="fileListContainer">
    <div class="cardTitleBox">
      <div class="title">文件列表</div>
      <div class="btnGroups">
        <button class="btn" @click="onReUpload">重新上传</button>
        <UploadFileBtn :accept="accept" :limit="limit" @onExceed="onUploadExceed" @onSuccess="onUploadSuccess">
          <button class="btn">
            <span class="icon upload_icon"></span>
            添加图片
          </button>
        </UploadFileBtn>
          <button class="btn download_btn" :disabled="allDownloading" @click="handleDownload">
            <span class="icon download_icon"></span>
            全部下载
          </button>
        <el-tooltip content="以压缩包形式下载">
          <img src="@/assets/img/zip.svg" alt="" style="height: 43px;margin-left: 10px;cursor: pointer;" @click="handleDownloadZip">
        </el-tooltip>
      </div>
    </div>
    <div class="cardList">
      <FileCard
        :file="file"
        v-for="(file, idx) in copyData"
        :key="idx"
        ref="cardRef"
        @onPreview="handlePreview"
        @download="downloadFile"
        @remove="onRemoveFile(idx)"
        class="cardItem"
      />
    </div>
    <div class="controlContainer">
      <slot></slot>
    </div>
    <PreviewModal :dialog-visible="visible" :file-info="fileInfo" @onClose="handleClosePreview" />
  </div>
</template>
<script>
import FileCard from '@/components/FileCard/index.vue'
import PreviewModal from '@/components/PreviewModal/index.vue'
import UploadFileBtn from '@/components/UploadFileBtn/index.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { VIP_LEVEL } from '@/config'
import {batchDownload, batchDownloadZip, download} from '@/utils'
import { compressGif, duce } from '@/api'
import { Message } from 'element-ui'
import { getToken } from '@/utils/token'
import { mixins } from '@/mixin'
// import { compressGif } from '@/api'

export default {
  name: 'FileList',
  components: {
    UploadFileBtn,
    PreviewModal,
    FileCard
  },
  mixins: [mixins],
  props: {
    limit: {
      type: Number,
      default: 10
    },
    accept: {
      type: String,
      default: '.png,.jpg,.jpeg,.bmp,.gif,.webp'
    },
    files: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      allDownloading: false, // 全部文件是否下载中
      copyData: [],
      downloadFiles: [], // 存储用户下载过的图片
      visible: false, // 预览弹窗的显示与隐藏
      fileInfo: {}
    }
  },
  computed: {
    ...mapGetters(['isVip']),
    ...mapState({
      allCert: state => state.allCert
    })
  },
  watch: {
    // 当传递过来的文件发生变化
    files: {
      handler(val) {
        this.copyData = val
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    ...mapActions(['updateUserInfo', 'setHistoryDownloads']),
    // 移除文件
    onRemoveFile(idx) {
      this.copyData.splice(idx, 1)
      if (this.copyData.length === 0) {
        this.onReUpload()
      }
    },
    // 打开预览弹窗
    handlePreview(fileInfo) {
      this.fileInfo = fileInfo
      this.visible = true
    },
    // 关闭预览弹窗
    handleClosePreview() {
      this.visible = false
    },
    // 检查是否允许下载
    async checkAllowDownload() {
      // 检查是否登录
      if (!getToken()) {
        this.$Login()
        return false
      }
      await this.$store.dispatch('updateUserInfo')

      // 检查用户是否是vip
      if (!this.isVip) {
        this.$_mixin_showPurchaseModal()
        return false
      }
      return true
    },
    // 下载当前图片，单次下载
    async downloadFile(fileInfo, options) {

      let findFile = this.downloadFiles.find(item => item.target?.fid === fileInfo.target?.fid)

      if (!findFile) {
        fileInfo.target['downloading'] = true
        this.downloadFiles.push(fileInfo)
      } else {
        if (findFile.target['downloading']) {
          return
        }
      }

      let flag = await this.checkAllowDownload()
      if (!flag) {
        this.downloadFiles.forEach(item => {
          if (item.target?.fid === fileInfo.target?.fid) {
            item.target['downloading'] = false
          }
        })
        return
      }
      // 如果是gif图片，则上传处理后在下载
      if (fileInfo?.origin?.mimeType === 'image/gif') {
        let res = await compressGif({
          blob: fileInfo.origin.raw,
          options: {
            q: options.quality === 1 ? 0.7 * 100 : options.quality * 100,
            w: fileInfo.origin.width,
            h: fileInfo.origin.height,
            to: 'gif'
          }
        })

        if (res.data.status === 0) {
          fileInfo.target.raw = res.data.data.imageaddr
          download(fileInfo.target.raw, fileInfo.origin.filename, fileInfo.origin.suffix)
        } else if (res.data.status === 600) {
          this.$Msg({
            content: '您的下载次数已用完！',
            showLeft: false,
            rightBtnTxt: '限时升级VIP',
            onClickRightBtn: () => {
              this.$_mixin_showPurchaseModal()
            }
          })
        } else {
          // gif下载失败
          Message({
            type: 'error',
            message: '图片下载失败！'
          })
          return
        }
      } else {
        let isCountVip = this.allCert?.vip === VIP_LEVEL.COUNT_VIP // 判断用户是不是次数vip
        if (isCountVip) {
          // 查看历史下载记录里是否包含
          let filterData = this.$store.state.historyDownloads.filter(item => item.fid === fileInfo.target?.fid)
          if (filterData.length) {
            // 下载过
            download(fileInfo.target.raw, fileInfo.origin.filename, fileInfo.origin.suffix)
          } else {
            let res = await duce(1)
            if (res.data.status === 0) {
              this.setHistoryDownloads([{ fid: fileInfo.target?.fid }]) // 添加到下载历史
              download(fileInfo.target.raw, fileInfo.origin.filename, fileInfo.target.suffix)
            } else if (res.data.status === 600) {
              this.$_mixin_showPurchaseModal()
            } else {
              Message({
                type: 'error',
                message: '下载失败'
              })
            }
          }
        } else {
          download(fileInfo.target.raw, fileInfo.origin.filename, fileInfo.origin.suffix)
        }
      }
      this.downloadFiles.forEach(item => {
        if (item.target?.fid === fileInfo.target?.fid) {
          item.target['downloading'] = false
        }
      })
      await this.$store.dispatch('updateUserInfo')
    },
    // 点击全部下载
    async handleDownload() {
      let f = this.downloadFiles.filter(item => {
        return item.target['downloading']
      })
      if (f.length > 0) {
        return
      }
      this.allDownloading = true
      let flag = await this.checkAllowDownload()
      if (!flag) {
        this.allDownloading = false
        return
      }
      let downloadFiles = [] // 待下载文件
      let isCountVip = this.allCert?.vip === VIP_LEVEL['COUNT_VIP'] // 判断用户是不是次数vip
      let downloadCounts = isCountVip ? this.allCert?.has_image_count : this.$refs.cardRef.length // 默认下载张数与处理的文件数量相等, 否则就是用户剩余的下载次数

      // 下载的时候如果是次数vip 要跟据用户剩余的次数，下载对应的张数
      for (let idx = 0; idx < this.$refs.cardRef.length; idx++) {
        let item = this.$refs.cardRef[idx]
        if (idx <= downloadCounts - 1) {
          // 如果图片类型是gif则上传处理后再下载
          if (item.fileInfo.origin.mimeType === 'image/gif') {
            let options = item.file.options
            let res = await compressGif({
              blob: item.fileInfo.origin.raw,
              options: {
                q: options.quality === 1 ? 0.7 : options.quality,
                w: item.fileInfo.origin.width,
                h: item.fileInfo.origin.height,
                to: 'gif'
              }
            })
            if (res.data.status === 0) {
              item.fileInfo.target.raw = res.data.data.imageaddr
            }
          }
          let { raw } = item.fileInfo.target
          let { filename, suffix } = item.fileInfo.origin
          downloadFiles.push({
            filename,
            suffix,
            raw,
            fid: item.fileInfo.target?.fid
          })
        } else {
          // TODO 提示用户下载次数用完，开通vip
        }
      }

      if (isCountVip) {
        let duceNumber = 0
        let historyList = []
        downloadFiles.forEach(item => {
          if (this.$store.state.historyDownloads.length === 0) {
            historyList.push(item)
          } else {
            let flag = this.$store.state.historyDownloads.some(it => it.fid === item.fid)
            if (flag) {
              duceNumber++
            } else {
              historyList.push(item)
            }
          }
        })
        // 扣除下载次数，扣除成功后执行下载
        let r = await duce(downloadFiles.length - duceNumber < 0 ? 0 : downloadFiles.length - duceNumber)
        if (r.data.status === 0) {
          this.setHistoryDownloads(historyList)
          await batchDownload(downloadFiles)
        } else if (r.data.status === 600) {
          this.$_mixin_showPurchaseModal()
        } else {
          Message({
            type: 'error',
            message: '下载失败！'
          })
        }
      } else {
        await batchDownload(downloadFiles)
      }
      this.allDownloading = false
      await this.$store.dispatch('updateUserInfo')
    },

    async handleDownloadZip() {
      let f = this.downloadFiles.filter(item => {
        return item.target['downloading']
      })
      if (f.length > 0) {
        return
      }
      this.allDownloading = true
      let flag = await this.checkAllowDownload()
      if (!flag) {
        this.allDownloading = false
        return
      }
      let downloadFiles = [] // 待下载文件
      let isCountVip = this.allCert?.vip === VIP_LEVEL['COUNT_VIP'] // 判断用户是不是次数vip
      let downloadCounts = isCountVip ? this.allCert?.has_image_count : this.$refs.cardRef.length // 默认下载张数与处理的文件数量相等, 否则就是用户剩余的下载次数

      // 下载的时候如果是次数vip 要跟据用户剩余的次数，下载对应的张数
      for (let idx = 0; idx < this.$refs.cardRef.length; idx++) {
        let item = this.$refs.cardRef[idx]
        if (idx <= downloadCounts - 1) {
          // 如果图片类型是gif则上传处理后再下载
          if (item.fileInfo.origin.mimeType === 'image/gif') {
            let options = item.file.options
            let res = await compressGif({
              blob: item.fileInfo.origin.raw,
              options: {
                q: options.quality === 1 ? 0.7 : options.quality,
                w: item.fileInfo.origin.width,
                h: item.fileInfo.origin.height,
                to: 'gif'
              }
            })
            if (res.data.status === 0) {
              item.fileInfo.target.raw = res.data.data.imageaddr
            }
          }
          let { raw } = item.fileInfo.target
          let { filename, suffix } = item.fileInfo.origin
          downloadFiles.push({
            filename,
            suffix,
            raw,
            fid: item.fileInfo.target?.fid
          })
        } else {
          // TODO 提示用户下载次数用完，开通vip
        }
      }

      if (isCountVip) {
        let duceNumber = 0
        let historyList = []
        downloadFiles.forEach(item => {
          if (this.$store.state.historyDownloads.length === 0) {
            historyList.push(item)
          } else {
            let flag = this.$store.state.historyDownloads.some(it => it.fid === item.fid)
            if (flag) {
              duceNumber++
            } else {
              historyList.push(item)
            }
          }
        })
        // 扣除下载次数，扣除成功后执行下载
        let r = await duce(downloadFiles.length - duceNumber < 0 ? 0 : downloadFiles.length - duceNumber)
        if (r.data.status === 0) {
          this.setHistoryDownloads(historyList)
          await batchDownloadZip(downloadFiles)
        } else if (r.data.status === 600) {
          this.$_mixin_showPurchaseModal()
        } else {
          Message({
            type: 'error',
            message: '下载失败！'
          })
        }
      } else {
        await batchDownloadZip(downloadFiles)
      }
      this.allDownloading = false
      await this.$store.dispatch('updateUserInfo')
    },

    // 上传超出限制
    onUploadExceed(files) {
      this.$emit('onExceed', files)
    },

    // 上传成功
    onUploadSuccess(file) {
      this.$emit('onSuccess', file)
    },
    onReUpload() {
      this.$emit('onReUpload')
    }
  },
  created() {
    this.copyData = this.files
  }
}
</script>
<style scoped lang="less">
@import url('./index.less');
</style>

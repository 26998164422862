import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
import { getToken, removeToken, setToken } from '@/utils/token'
import { getUserInfo, userLogin } from '@/api'
import { Message } from 'element-ui'
import pdfStore from './pdf.store'
import { trackLogin } from '@/utils/track'
import amplificationStore from "@/store/amplification.store";

const store = new Vuex.Store({
  modules: {
    pdfStore,
    amplificationStore
  },
  state: {
    userInfo: null, // 存放用户信息
    canFreeDownloadFiles: [], // 用于存储用户已经执行过扣除下载权限的图片
    historyDownloads: [],
    deferredPrompt: null,
    allCert: {}, // 用户权限信息
    isShowCollecting: false
  },
  getters: {
    isLogin(state) {
      return state.userInfo !== null && Object.keys(state.userInfo).length !== 0
    },
    // 是不是vip
    isVip(state) {
      return state.allCert?.vip !== 0 // 0：不是vip，5:次数vip，10:时间vip，100:永久vip 7: 3天VIP
    },
    // 是不是时间VIP
    isTimeVIP(state) {
      return state.allCert?.vip === 10 || state.allCert?.vip === 7
    },
    // 是不是永久VIP
    isCfVIP(state) {
      return state.allCert?.vip === 100
    },
    // 是不是次数VIP
    isCountVIP(state) {
      return state.allCert?.vip === 5
    },
    isThreeDayVIP(state) {
      return state.allCert?.vip === 7
    },

    getUserInfo(state) {
      return state.userInfo
    },
    getAllCert(state) {
      return state.allCert
    }
  },
  mutations: {
    setUserInfo(state, payload) {
      state.userInfo = { ...state.userInfo, ...payload }
      setToken(payload)
    },
    updateAllCert(state, payload) {
      // 更新本地的用户信息
      state.allCert = { ...state.allCert, ...payload }
      state.userInfo = { ...state.userInfo, uid: payload?.uid }
    },
    logout(state) {
      state.userInfo = null
      state.allCert = null
      removeToken()
    },
    setCanFreeDownloadFiles(state, payload = []) {
      state.canFreeDownloadFiles = payload
    },
    setHistoryDownloads(state, payload = []) {
      state.historyDownloads = state.historyDownloads.concat(payload)
    },
    resetHistoryDownloads(state) {
      state.historyDownloads = []
    }
  },
  actions: {
    /**
     * @description 登录
     * @param commit
     * @param payload
     * @returns {Promise<void>}
     */
    async login({ commit }, payload) {
      let { code } = payload
      if (!getToken() && code) {
        let res = await userLogin(code)
        if (res.data.status === 0) {
          setToken(res.data.data)
          await commit('setUserInfo', res.data.data)
          trackLogin()
          Message({
            type: 'success',
            message: '登录成功',
            duration: 2000
          })
        }
      }
    },
    /**
     * @description 退出登录
     * @param commit
     */
    logout({ commit }) {
      commit('logout')
    },
    /**
     * @description 设置用户信息
     * @param commit
     * @param payload
     */
    setUserInfo({ commit }, payload) {
      commit('setUserInfo', payload)
    },
    updateAllCert({ commit }, payload) {
      commit('updateAllCert', payload)
    },
    /**
     * 更新用户信息
     */
    async updateUserInfo({ commit }) {
      let res = await getUserInfo()
      if (res.data.status === 0) {
        commit('updateAllCert', res.data.data)
      }
    },
    setCanFreeDownloadFiles({ commit }, payload = []) {
      commit('setCanFreeDownloadFiles', payload)
    },
    // 设置下载历史记录
    setHistoryDownloads({ commit }, payload = []) {
      commit('setHistoryDownloads', payload)
    },
    // 重置下载历史
    resetHistoryDownloads({ commit }) {
      commit('resetHistoryDownloads')
    },
    setDeferredPrompt({ state }, e) {
      state.deferredPrompt = e
    },
    installApp({ state }) {
      state.deferredPrompt?.prompt()
    },
    setIsShowCollecting({ state }, payload) {
      state.isShowCollecting = payload
    }
  }
})

export default store
